/**
 * Remember to import it in gatsby-browser.js
 */

import React, { useState, createContext } from 'react'

import { setOverflowHidden } from '../../utils/helpers'

const initialState = {
  state: {
    showMenu: false,
    isGalleryOpen: false
  },
  actions: {
    toggleMenu: () => null
  }
}

const AppContext = createContext(initialState)

export const AppProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  return (
    <AppContext.Provider
      value={{
        state: state,
        actions: {
          toggleMenu: condition => {
            const newCondition =
              typeof condition === 'boolean' ? condition : !state.showMenu
            setOverflowHidden(newCondition)
            setState(state => ({
              ...state,
              showMenu: newCondition
            }))
          },
          toggleGallery: bool => {
            if (bool) {
              setState(prevState => ({
                ...prevState,
                isGalleryOpen: bool
              }))
            } else {
              setState(prevState => ({
                ...prevState,
                isGalleryOpen: !prevState.isGalleryOpen
              }))
            }
          }
        }
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
